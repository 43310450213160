import React from "react";
import { BrowserRouter } from "react-router-dom";
import MainLayout from './components/MainLayout';
import NavBar from './components/NavBar';
import './App.css';

const App = () => {
    
    return (
        <BrowserRouter>
            <div className="mainapp-container">
                <NavBar />
                <MainLayout />
            </div>
        </BrowserRouter>
    )
};

export default App;
