import React from 'react';

const Me = () => {

    const handleScroll = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
      };

    return (
        <div style={{ height: '100vh' }} className='me-container'>
            <h1>Hi, I'm Kevin Musembi</h1>
            <h3>
                A backend software engineer with a passion for creating stable, secure web applications. Current stack - Python Flask, Django, MongoDB, React JS, Git.
            </h3>
            <button onClick={() => handleScroll('projects')}>View Projects</button>
        </div>
    );
};

export default Me;
