import React from "react";
// import { Link } from "react-router-dom";

const NavBar = () => {

    const handleScroll = (id) => {
        document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
      };
    
    return (
        <nav className="navbar-container">
            <img src="/musembi.jpg" alt="Musembi" onClick={() => handleScroll('me')} />
            {/*<li><Link to="/">Me</Link></li>*/}
            {/*<li><a href="#me">Me</a></li>*/}
            <div className="nav-buttons">
                <button onClick={() => handleScroll('me')}>Me</button>
                <button onClick={() => handleScroll('projects')}>Projects</button>
                <button onClick={() => window.open('/resume.pdf', '_blank')}>Resume</button>
                <button onClick={() => handleScroll('contacts')}>Contacts</button>
            </div>
        </nav>
    );
};

export default NavBar;
