import React from "react";
import emailjs from 'emailjs-com';

const Contacts = () => {

    const userId = process.env.REACT_APP_USER_ID
    const serviceId = process.env.REACT_APP_SERVICE_ID
    const templateId = process.env.REACT_APP_TEMPLATE_ID

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(serviceId, templateId, e.target, userId)
          .then(() => {
            alert('Message sent!');
            e.target.reset();
          })
          .catch((error) => console.error(error));
      };

    return (
        <div style={{ height: '100vh' }} className="contact-container">
            <h2>Kindly reach out</h2>
            <form onSubmit={sendEmail}>
                <input type="email" name="user_email" placeholder="Your Email" required />
                <textarea name="message" placeholder="Your Message" required></textarea>
                <button type="submit">Send</button>
            </form>
            <h3 style={{ color: '#483d8b' }}>Email: kevinmusembi.m@gmail.com</h3>
            <div className="icons">
                <a href="https://www.linkedin.com/in/kevin-musembi/" target="_blank" rel="noopener noreferrer">
                    <img src="/linkedin.png" alt="LinkedIn" style={{ width: '30px', height: '30px' }} />
                </a>
                <a href="https://github.com/K-Musembi" target="_blank" rel="noopener noreferrer">
                    <img src="/github-sign.png" alt="GitHub" style={{ width: '30px', height: '30px' }} />
                </a>
                <a href="https://x.com/musembi_home" target="_blank" rel="noopener noreferrer">
                    <img src="/twitter.png" alt="X (Twitter)" style={{ width: '30px', height: '30px' }} />
                </a>
            </div>
        </div>
    );
};

export default Contacts;
