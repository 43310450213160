import React from "react";
import Me from "./Me";
import Projects from "./Projects";
import Contacts from "./Contacts";

const MainLayout = () => {
    
    return (
        <div className="mainlayout-container">
            <section id="me">
                <Me />
            </section>
            <section id="projects">
                <Projects />
            </section>
            <section id="contacts">
                <Contacts />
            </section>
        </div>
    );
};

export default MainLayout;
